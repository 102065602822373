<template>
  <section id="dashboard-ecommerce">
    <b-row v-if="!isCashier" class="match-height">
      <b-col xl="12" md="6">
        <ecommerce-statistics :data="statisticsItems" />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6" md="6">
        <b-card class="mb-2">
          <b-form ref="products">
            <b-row>
              <b-col>
                <b-form-group label="Fecha">
                  <b-form-datepicker locale="es" :hide-header="true" placeholder="" v-model="pfrom" name="from" />
                </b-form-group>
                <v-select class="mx-select" v-model="school_id_pfrom" :options="schoolOptions"
                  :reduce="(item) => item.id" placeholder="Colegios" label="name" :clearable="false" />
              </b-col>
            </b-row>

            <b-card-body class="statistics-body">
              <b-row>
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" :variant="'light-danger'">
                      <feather-icon size="24" icon="TrendingUpIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h3>Productos</h3>
                  </b-media-body>

                </b-media>
                <b-col class="d-flex justify-content-center">
                  <b-button @click="action('products', 'pdf', pfrom, school_id_pfrom)" type="button" variant="primary"
                    class="mr-1 f11">
                    PDF
                  </b-button>
                  <b-button @click="action('products', 'xlsx', pfrom, school_id_pfrom)" type="button"
                    variant="outline-secondary" class="mr-1 f11">
                    EXCEL
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </b-card>
      </b-col>
      <b-col xl="6" md="6">
        <b-card class="mb-2">
          <b-form ref="sales">
            <b-row>
              <b-col>
                <b-form-group label="Fecha">
                  <b-form-datepicker locale="es" :hide-header="true" placeholder="" v-model="pfrom" name="from" />
                </b-form-group>
                <v-select class="mx-select" v-model="school_id_pfrom" :options="schoolOptions"
                  :reduce="(item) => item.id" placeholder="Colegios" label="name" :clearable="false" />
              </b-col>
            </b-row>
            <b-card-body class="statistics-body">
              <b-row>
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" :variant="'light-danger'">
                      <feather-icon size="24" icon="TrendingUpIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h3>Ventas</h3>
                  </b-media-body>
                  
                </b-media>
                <b-col class="d-flex justify-content-center">
                    <b-button @click="action('sales', 'pdf', pfrom, school_id_pfrom)" type="button" variant="primary"
                      class="mr-1 f11">
                      PDF
                    </b-button>
                    <b-button @click="action('sales', 'xlsx', pfrom, school_id_pfrom)" type="button"
                      variant="outline-secondary" class="mr-1 f11">
                      EXCEL
                    </b-button>
                  </b-col>
              </b-row>
            </b-card-body>
          </b-form>
        </b-card>
      </b-col>
      <b-col xl="6" md="6">
        <b-card class="mb-2">
          <b-form ref="refills">
            <b-row>
              <b-col>
                <b-form-group label="Fecha">
                  <b-form-datepicker locale="es" :hide-header="true" placeholder="" v-model="pfrom" name="from" />
                </b-form-group>
                <v-select class="mx-select" v-model="school_id_pfrom" :options="schoolOptions"
                  :reduce="(item) => item.id" placeholder="Colegios" label="name" :clearable="false" />
              </b-col>
            </b-row>
            <b-card-body class="statistics-body">
              <b-row>
                <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" :variant="'light-danger'">
                    <feather-icon size="24" icon="TrendingUpIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h3>Recargas</h3>
                </b-media-body>
               
              </b-media>
              <b-col class="d-flex justify-content-center">
                  <b-button @click="action('refills', 'pdf', pfrom, school_id_pfrom)" type="button" variant="primary"
                    class="mr-1 f11">
                    PDF
                  </b-button>
                </b-col>
              </b-row>
              
            </b-card-body>
          </b-form>
        </b-card>
      </b-col>
      <b-col xl="6" md="6">
        <b-card class="mb-2">
          <b-form ref="usersBalance">
            <b-row>
              <b-col>
                <v-select class="mx-select" v-model="school_id_pfrom" :options="schoolOptions"
                  :reduce="(item) => item.id" placeholder="Colegios" label="name" :clearable="false" />
              </b-col>
            </b-row>
            <b-card-body class="statistics-body">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" :variant="'light-danger'">
                    <feather-icon size="24" icon="TrendingUpIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h3>Saldos</h3>
                </b-media-body>
                <b-col>
                  <b-button @click="action('users-balance', 'xlsx', null, school_id_pfrom)" type="button"
                    variant="outline-secondary" class="mr-1 f11">
                    EXCEL
                  </b-button>
                </b-col>
              </b-media>
            </b-card-body>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BLink,
  BForm,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
} from "bootstrap-vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { clear } from "echarts/lib/util/throttle";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BLink,
    EcommerceStatistics,
    BForm,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      rrfrom: null,
      // '2024-08-16'
      pfrom: new Date().toISOString().substr(0, 10),
      sfrom: null,
      pdisabled: true,
      sdisabled: true,
      rrdisabled: true,
      statisticsItems: [],
      schoolOptions: [],
      school_id_pfrom: null,
      school_id_sfrom: null,
      school_id_rrfrom: null,
      school_id: null,
    };
  },
  watch: {
    pfrom() {
      this.pdisabled = false;
    },
    sfrom() {
      this.sdisabled = false;
    },
    rrfrom() {
      this.rrdisabled = false;
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL + "api/";
    },
    isCashier() {
      return this.$store.getters["user/userData"].roles.find(
        (role) => role.name == "cashier"
      );
    },
  },
  mounted() {
    this.fetchEcommerceData();
    this.getSchools();
  },
  methods: {
    async fetchEcommerceData() {
      this.$store.dispatch("EcommerceData/index").then((response) => {
        this.statisticsItems = response.data.statisticsItems;
      });
    },
    async getSchools() {
      this.schoolOptions = await this.$store.dispatch("schools/index", {
        schools: true,
      });
      this.school_id_pfrom = this.schoolOptions[0]?.id;
    },
    async action(enpoint, type, date, school_id) {
      this.$store
        .dispatch("EcommerceData/reports", {
          endpoint: enpoint,
          type: type,
          responseType: "blob",
          params: {
            date: date,
            school_id: school_id,
          },
        })
        .then((res) => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `reporte_${this.getName(enpoint)}_${Date.now()}.${type}`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          let status = error.response.status;
          if (status == 404) {
            this.message(
              "AlertCircleIcon",
              "danger",
              "No se encontro la tasa de cambio del día seleccionado: " +
              date +
              " fue copiada la fecha al portapapeles agreguelo en la configuracion del banco e intente descargar de nuevo el reporte"
            );
            navigator.clipboard.writeText(date);
          } else if (status == 500) {
            this.message(
              "AlertCircleIcon",
              "danger",
              "Ocurrio un error en el servidor"
            );
          }
        });
    },
    message(icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Respuesta",
          icon: icon,
          variant: variant,
          text: text,
        },
      });
    },
    getName(enpoint) {
      switch (enpoint) {
        case "refills":
          return "recargas";
        case "sales":
          return "ventas";
        case "products":
          return "productos";
        case "users-balance":
          return "saldos";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.b-form-btn-label-control.form-control>label {
  font-size: 12px !important;
}

.f11 {
  font-size: 11px !important;
  max-height: 35px !important;
}
</style>
